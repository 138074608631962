import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './BankSlider.scss';

function BankSlider({ imgUrl, data, settings, className, itemClassName }) {
  return (
    <div className={`bank-logo-slider overflow-hidden ${className || ''}`}>
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="item">
            <div
              className={
                `${itemClassName || ''} ${d.className || ''}` || 'max-w-[80px]'
              }>
              <img
                className="w-full max-w-[130px]"
                src={`${imgUrl}/${d.src}`}
                alt={d.alt}
                width={260}
                height={96}
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
BankSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      className: PropTypes.string,
    }),
  ),
  imgUrl: PropTypes.string,
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  settings: PropTypes.shape({}),
};
BankSlider.defaultProps = {
  data: [],
  className: '',
  itemClassName: '',
  imgUrl: '',
  settings: {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    pauseOnHover: false,
    draggable: false,
    centerMode: true,
    speed: 1000,
    variableWidth: true,
    cssEase: 'linear',
    // easing: 'linear',
  },
};
export default BankSlider;
