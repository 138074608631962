import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import SliderButton from '../../SliderEasySplit/SliderButton';
import { verificationData } from '../../../content/nbfc';
import './VerificationSlider.scss';

function VerificationSlider({ data, settings, imageBaseUrl }) {
  return (
    <div className="verfication-slider md:ml-3.5 pb-[36px] md:pb-0">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.id} className="px-3.5">
            <picture>
              <source
                srcSet={`${imageBaseUrl}/${d.image.src}`}
                media="(min-width:768px)"
                width="1182"
                height="1062"
              />
              <img
                src={`${imageBaseUrl}/${d.image.msrc}`}
                alt={d.title}
                width="558"
                height="578"
                className="w-full"
              />
            </picture>
          </div>
        ))}
      </Slider>
    </div>
  );
}

VerificationSlider.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.shape({}),
      title: PropTypes.string,
      background: PropTypes.string,
    }),
  ),
  imageBaseUrl: PropTypes.string,
  settings: PropTypes.shape({}),
};

VerificationSlider.defaultProps = {
  data: verificationData,
  imageBaseUrl: '',
  settings: {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    // slidesToScroll: 1,
    nextArrow: <SliderButton left={false} />,
    prevArrow: <SliderButton />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,

          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,

          infinite: false,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,

          infinite: false,
          arrows: true,
          dots: true,
        },
      },
    ],
  },
};

export default VerificationSlider;
