import React, { Fragment, useRef } from 'react';
import CheckList from '../components/CheckList/CheckList';
import FAQ from '../components/FAQ';
import GetStarted from '../components/GetStarted';
import Icon from '../components/Icon';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Tabs from '../components/Tabs/Tabs';
import VerificationSlider from '../components/Sliders/VerificationSlider/VerificationSlider';
import CountUp from '../components/CountUp/CountUp';
import BankSlider from '../components/Sliders/BankSlider/BankSlider';
import BulletPoint from '../components/common/BulletPoint/BulletPoint';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import {
  checkList,
  faqData,
  ourSolution,
  instantLoanDisbursal,
  merchantDashboard,
  riskMitigation,
  digitalLendingGuidelines,
  paymentPartnerStats,
  perksCards,
  bankSliderData,
  sliderSettings,
  northernArcCards,
  comparisonTable,
} from '../content/nbfc';
import '../styles/nbfc.scss';

function NBFCPage(rest) {
  const container = useRef();
  return (
    <Layout
      lightHeader
      {...rest}
      signInLink="https://merchant.cashfree.com/merchant/login?source-action=NBFC%20lending%20solution&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=NBFC%20lending%20solution&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Digital lending solutions for NBFCs & Fintechs - Cashfree Payments"
        description="Create Escrow accounts, operate through our digital lending solutions' APIs for loan disbursals, repayments & co-lending, in compliance with RBI's digital lending guidelines"
        url="http://cashfree.com/nbfc-digital-lending-solutions/"
        keywords={[
          'Digital lending solution',
          'escrow',
          'NBFC',
          'Payouts Lending',
          'NBFC Solutions',
          'Lending API',
          'Payment Collection Platform',
          'NBFC Lending',
          'Loan Repayment API',
          'Loan API Provider in India',
          'Repayment Collection',
          'Money lending API',
          'Loan Solutions',
          'Lending Solutions',
          'Loan management system',
          'Digital lending platform',
          'NBFC Business loan',
          'NBFC Instant Loan',
        ]}
      />
      <section
        className="pb-0 pt-[110px] md:pt-[80px]  lg:pb-[86px] bg-cf-dark text-white nbfc-hero overflow-hidden"
        ref={container}>
        <div className="container position-relative z-10">
          <div className="flex flex-wrap lg:flex-nowrap justify-start lg:justify-between lg:w-[110%]">
            <div className="w-full lg:w-1/2 pb-0 lg:self-center">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                NBFC and Fintech
              </div>
              <div className="mb-3 md:mb-5 text-xl md:text-2xl font-semibold max-w-[600px] font-heading">
                A full-stack{' '}
                <h1 className="inline">digital lending solution</h1> for NBFCs
                and Fintechs
              </div>
              <div className="text-opacity-80 max-w-[600px] text-[16px] leading-[24px] md:text-2.5md  font-body">
                Create escrow accounts and operate through APIs for loan
                disbursements, repayments and co-lending, in compliance with
                RBI’s{' '}
                <h2 className="inline font-body">digital lending guidelines</h2>
                .
              </div>
              <ul className="p-0 mt-3 md:mt-7 mb-4 md:mb-[48px] list-none max-w-full flex flex-wrap flex-row md:items-center justify-start">
                {checkList.map(({ id, listItem }) => (
                  <li
                    className="mb-[15px] items-center md:mb-0 mr-[24px] lg:mr-4 xl:mr-[24px] last:mr-0 flex "
                    key={id}>
                    <span className="w-5 h-5 flex flex-none justify-center items-center rounded-full mr-2 bg-white  bg-opacity-25">
                      <Icon
                        name="tick"
                        width="9.21px"
                        height="6.29px"
                        fill="#fff"
                      />
                    </span>
                    <h3 className="font-body !m-0 text-2.5sm md:text-md">
                      {listItem}
                    </h3>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap md:flex-nowrap justify-between md:justify-start">
                <a
                  className="button button-green w-full  md:w-auto mb-4 md:mb-0 mr-0 md:mr-4"
                  href="https://merchant.cashfree.com/merchants/signup?source-action=NBFC%20lending%20solution&action=Sign%20Up&button-id=StartNow_Hero">
                  Create Account <span className="chevron" />
                </a>
                <a
                  className="button button-outline no-shadow text-white border-cf-stroke w-full md:w-auto"
                  href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_Hero">
                  Contact Sales <span className="chevron !mb-0" />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-1/2 lg:pl-[19px] flex justify-center h-full items-center">
              <TransparentVideo
                containerClass="nbfc-hero-video lg:left-[-10%]"
                backgroundRef={container}
                className="relative z-10"
                poster="/img/nbfc/poster.png"
                width="1700"
                height="1700"
                mp4Src="/img/nbfc/hero-video.mp4"
                webmSrc="/img/nbfc/hero-video.webm"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative payments-partner text-center overflow-hidden">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold mb-5 font-heading ">
            Your Payments Partner
          </div>
          <div className="leading-[24px] mb-8 md:mb-16 mx-auto ">
            India&apos;s 1<sup>st</sup> Fintech platform to enable disbursal of
            loans and insurance claim settlements
          </div>
          <div className="flex flex-wrap mx-[-21px]  md:-mx-3.5">
            {paymentPartnerStats.map((i) => (
              <div key={i.id} className="px-3.5 w-1/3">
                <div className="bg-[#ffffff14] h-full rounded border border-[#ffffff23] flex flex-col justify-start">
                  <div className="text-base md:text-[42px] md:leading-[48px] text-cf-primary font-bold md:font-semibold text-center">
                    <CountUp
                      {...i.countUpProps}
                      duration={4}
                      ease="circ.out"
                      alwaysCenter={false}
                    />
                  </div>
                  <div className="leading-4 md:leading-6 md:mt-2 text-tiny md:text-base text-center">
                    {i.desc}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-2 relative overflow-hidden bg-cf-light-grey">
        <div className="container">
          <div className="w-full md:w-5/6 md:mx-auto mb-5 md:mb-16 md:text-center self-center text-shs md:text-shl font-heading font-semibold">
            Our tailored solution enables NBFCs and their partner LSPs to scale
            their <h2 className="inline">digital lending&nbsp;business</h2>
          </div>

          <div className="flex flex-wrap -mx-[8px] md:-mx-3.5 md:-mt-[52px] nbfc-use-case">
            {ourSolution.map(({ id, image, title, disabled }) => (
              <div
                key={id}
                className="px-[8px] md:px-3.5 pt-[16px] md:pt-[52px] w-1/2 md:w-1/3 lg:w-1/6">
                <div
                  className={`bg-white px-2 py-6 md:p-[16px] h-full min-h-[156px] rounded card flex items-center text-center flex-col relative ${
                    disabled ? 'disabled' : ''
                  }`}>
                  <img
                    src={`/img/nbfc/${image}`}
                    alt={title}
                    width="40"
                    height="40"
                    className={`w-[40px] h-[40px] md:w-[48px] md:h-[48px] ${
                      disabled ? 'grayscale' : ''
                    }`}
                  />
                  <div
                    className={`text-md mt-3 font-semibold ${
                      disabled ? 'text-cf-faded-black' : ''
                    }`}>
                    {title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-2 bg-cf-dark relative nbfc-perks-container">
        <div className="container">
          <h2 className="text-shs md:text-shl font-semibold mb-8 md:mb-16 text-white font-heading lg:w-1/2 mx-auto text-center">
            Managed Escrow Solution for NBFCs and&nbsp;LSPs
          </h2>
          <div className="flex flex-wrap flex-start items-stretch md:-mx-3.5">
            {perksCards.map((card) => (
              <div
                className="escrow-card md:px-3.5 w-full md:w-1/2 mb-6 last:mb-0 md:mb-0 "
                key={card.id}>
                <div className="relative px-6 py-7 md:p-[40px] bg-white bg-opacity-10 rounded h-full flex flex-col justify-between">
                  <div>
                    <div className="flex items-center">
                      <div className="w-[40px] h-[40px] md:w-[48px] md:h-[48px]">
                        <img
                          className="w-full"
                          src={card.icon}
                          alt={card.title}
                          width="40"
                          height="40"
                        />
                      </div>
                      <p className="pl-3 md:pl-4 text-[16px] leading-6 font-semibold md:text-3md text-white">
                        {card.title}
                      </p>
                    </div>

                    <div className="mt-8 md:mt-6 pb-3 md:pb-4 text-cf-yellow font-semibold text-2.5sm">
                      Perks
                    </div>
                    <div className="text-cf-cold-purple">
                      <CheckList
                        data={card.perks}
                        className="mb-0"
                        fontClass="text-2.5sm leading-6"
                      />
                    </div>
                  </div>
                  <a
                    className="button button-green mt-8 md:mt-10 wide w-full md:max-w-max"
                    href={card.link}>
                    Contact Sales <span className="chevron" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-3 relative overflow-hidden">
        <div className="container">
          <div className="max-w-[803px] text-shs md:text-shl font-heading font-semibold mb-[16px] md:mb-0">
            Make <h2 className="inline">instant loan disbursals</h2> directly
            from your current or escrow accounts with Cashfree Payments’ APIs
          </div>
          <div className="flex flex-col-reverse md:flex-col">
            <a
              className="button button-green mt-8 md:mb-16 wide w-full md:max-w-max"
              href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_MakeInstantLoanDisbursals">
              Contact Sales <span className="chevron" />
            </a>
            <div>
              <div className="flex flex-wrap md:-mx-3.5">
                {instantLoanDisbursal.map(({ id, icon, title, text }) => (
                  <div className="w-full mt-[16px] md:px-3.5 md:w-1/4" key={id}>
                    <div className="px-4 py-6 bg-cf-light-grey h-full lg:min-h-[286px] rounded">
                      <img
                        src={`/img/nbfc/${icon}`}
                        className="w-[40px] h-[40px] md:w-[48px] md:h-[48px]"
                        width="40"
                        height="40"
                        alt=""
                      />
                      <div className="font-body font-bold md:font-semibold mt-4 mb-2">
                        {title}
                      </div>
                      <div className="text-base mb-6">{text}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center overflow-hidden mt-6 md:mt-16 pt-6 md:pt-9 border-t-2 border-dashed border-cf-stroke">
                <div className="mr-6 min-w-[140px] font-semibold whitespace-nowrap flex-shrink-0">
                  Banking Partners:
                </div>
                <div className="flex-shrink-0 flex-1">
                  <BankSlider
                    imgUrl="/img/nbfc"
                    itemClassName="mx-4"
                    data={bankSliderData}
                    settings={sliderSettings}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-2 relative overflow-hidden  bg-cf-light-grey">
        <div className="container">
          <div className="flex flex-wrap md:-mx-3.5">
            <div className="w-full md:w-1/2 self-center">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                Powerful merchant dashboard
              </p>
              <div className="text-3md md:text-4md font-semibold text-cf-hero mb-4 md:mb-8">
                <h2 className="inline font-body">Disburse loans</h2> and make
                other payments directly from your connected
                business&nbsp;account
              </div>
              <div className="max-w-[500px]">
                <CheckList data={merchantDashboard} className="mb-0" />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <picture>
                <img
                  className="w-full md:w-full pt-7 md:pt-0"
                  src="/img/nbfc/merchant-dashboard.png"
                  alt="merchant-dashboard"
                  width="2025"
                  height="1254"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section className="md:pt-[128px]">
        <div className="container no-padding">
          <div className=" py-[48px] md:py-[96px] px-[16px] md:px-[56px] bg-cf-primary nbfc-northern-arc relative overflow-hidden">
            <div className="flex flex-wrap">
              <div className="w-full flex justify-center items-center">
                <div className="max-w-[109px] border-r border-r-cf-stroke pr-5 mr-5 pb-3">
                  <img
                    src="/img/nbfc/northern-arc.png"
                    alt="Northern Arc"
                    width="328"
                    height="180"
                  />
                </div>
                <div className="max-w-[119px]">
                  <img
                    src="/img/nbfc/cashfree.png"
                    alt="Cashfree"
                    width="359"
                    height="104"
                  />
                </div>
              </div>
            </div>
            <div className="text-white text-center text-3md md:text-4md font-semibold mt-5 mb-3">
              Streamline loan disbursals with direct Payouts&nbsp;integration
            </div>
            <div className="flex justify-center text-center mb-8 md:mb-11 text-2.5sm md:text-base">
              <div className="w-full md:max-w-[900px] md:mx-auto text-cf-light-cold-purple">
                <b className="text-white">Cashfree Payments</b> helped{' '}
                <b className="text-white">Northern Arc</b> provide instant
                consumer &{' '}
                <h2 className="inline font-body">business loan disbursals</h2>{' '}
                and effective{' '}
                <h2 className="inline font-body">loan solutions</h2> to all, by
                simply integrating <b className="text-white">direct</b> Payouts
                in their Loan Origination System, eSthenos. With this, Northern
                Arc could:
              </div>
            </div>
            <div className="flex flex-wrap md:mx-[-8.5px]">
              {northernArcCards.map((i) => (
                <div
                  className="w-full pt-[16px] first:pt-0 md:pt-0 md:px-[8.5px] md:w-1/3"
                  key={i.id}>
                  <div className="px-4 md:px-6 py-6 md:py-8 bg-white bg-opacity-10 h-full rounded border border-white border-opacity-10">
                    <div className="w-[32px] h-[32px] md:w-[40px] md:h-[40px]">
                      <img
                        className="w-full"
                        src={i.image}
                        alt={i.boldText}
                        width="40"
                        height="40px"
                      />
                    </div>
                    <div className="leading-6 text-cf-light-cold-purple mt-3 md:mt-4 text-2sm md:text-base">
                      <b className="text-white">{i.boldText}</b> {i.desc}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="p-section-3 bav-verification">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold max-w-[826px]  mb-6 md:mb-8 font-heading">
            Effortlessly collect payments while adhering to RBI&apos;s digital
            lending guidelines
          </div>
          <Tabs
            data={digitalLendingGuidelines}
            dropDown
            timerAutoPlay
            dropDownFontSize="text-base"
          />
        </div>
      </section>
      <section className="p-section-2 bg-cf-light-grey overflow-hidden">
        <div className="container">
          <div className="flex flex-col items-center mb-8 md:mb-16">
            <div className="text-sm font-semibold  text-cf-primary pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
              Verify Borrower identity
            </div>
            <div className="font-heading text-center text-shs md:text-shl font-semibold mx-auto max-w-[770px] mb-3 md:mb-6">
              Onboard borrowers with ease using our
              <span className="md:hidden">&nbsp;</span>
              <span className="hidden md:inline"> </span>360°
              Verification&nbsp;Suite
            </div>
            <div className="text-center leading-[24px] mx-auto max-w-[820px] ">
              <span className="text-cf-primary">
                [For individuals and businesses]
              </span>{' '}
              Provide an easy onboarding experience for your users by verifying
              their bank accounts & UPI IDs, PAN, Aadhaar and GSTIN using our
              APIs and dashboard.
            </div>
          </div>
          <VerificationSlider imageBaseUrl="/img/nbfc" />
        </div>
      </section>
      <section className="p-section-2 bg-cf-dark relative nbfc-table-section text-white text-center">
        <div className="container">
          <div className="text-shs md:text-shl font-semibold mb-8 md:mb-5  font-heading max-w-[850px] mx-auto">
            Revolutionize lending with Cashfree Payment&apos;s solution for
            NBFCs & LSPs
          </div>

          <table className="mt-14 mx-auto text-cf-hero hidden md:block">
            <tbody>
              <tr>
                <th>&nbsp;</th>
                <th>With Cashfree Payments</th>
                <th>With Other Platforms</th>
              </tr>
              {comparisonTable.map((row) => (
                <tr key={row.id}>
                  <th>
                    <div>{row.title}</div>
                  </th>
                  {row.columns.map((c, i) => (
                    <td key={c.id}>
                      <BulletPoint
                        className="text-base"
                        text={<span>{c.content}</span>}
                        type={i % 2 === 0 ? 'green-tick' : 'red-purple-cancel'}
                      />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="grid grid-cols-2 md:hidden border border-cf-stroke border-opacity-10 rounded text-left mobile-table">
            <div className="text-white font-semibold px-2 pt-[28px] pb-6 text-center text-base">
              With <div>Cashfree Payments</div>
            </div>
            <div className="text-cf-cold-purple px-2 pt-[28px] pb-6 font-semibold text-center text-base">
              With <div>Other Platforms</div>
            </div>
            {comparisonTable.map((row) => (
              <Fragment key={row.id}>
                <div className="col-span-2 bg-[#42256B] px-[24px] py-[12px] text-cf-yellow font-semibold text-tiny relative z-[1] text-center">
                  {row.title}
                </div>
                {row.columns.map((c, i) => (
                  <div
                    className="py-[16px] px-[12px] text-white/60 mobile-points"
                    key={c.id}>
                    <BulletPoint
                      className="text-2.5sm"
                      text={c.content}
                      type={i % 2 === 0 ? 'green-tick' : 'red-purple-cancel'}
                    />
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      </section>
      <section className="p-section-3 relative bg-cf-light-grey">
        <div className="container">
          <div className="flex flex-col-reverse md:flex-row flex-wrap md:-mx-3.5 md:items-center">
            <div className="w-full md:w-1/2 md:px-3.5 pt-7 md:pt-0">
              <picture>
                <img
                  className="w-full md:max-w-[540px]"
                  src="/img/nbfc/risk-mitigation.png"
                  alt="risk-mitigation"
                  width="1080"
                  height="785"
                />
              </picture>
            </div>
            <div className="w-full md:w-1/2 md:px-3.5">
              <p className="text-sm font-semibold text-cf-primary pl-5 mb-2 uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px]">
                Risk Mitigation
              </p>
              <p className=" text-shl font-semibold text-cf-hero mb-3 md:mb-5 font-heading max-w-[520px]">
                Real time risk management with powerful risk engine
              </p>
              <div className="leading-[24px] mb-6 max-w-[430px] text-base">
                Have better control on funds movement, take action, and minimize
                risk with real-time updates.
              </div>
              <div className="max-w-[500px]">
                <CheckList
                  data={riskMitigation}
                  className="mb-0"
                  fontClass="text-base"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container general-faqs">
          <FAQ
            open="0"
            data={faqData}
            supportLinkHref="https://www.cashfree.com/help/hc"
          />
        </div>
      </section>
      <GetStarted
        startNowLink="https://merchant.cashfree.com/merchants/signup?source-action=NBFC%20lending%20solution&action=Sign%20Up&button-id=StartNow_Footer"
        getInTouchLink="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_Footer"
      />
    </Layout>
  );
}

export default NBFCPage;
