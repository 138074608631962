import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import PropTypes from 'prop-types';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const CountUp = ({
  useIndianFormat,
  useComma,
  suffix,
  prefix,
  value,
  startValue,
  duration,
  ease,
  animate,
  alwaysCenter,
}) => {
  const ref = useRef();
  const baseRef = useRef();
  useEffect(() => {
    const count = { value: startValue };
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ref.current,
        // markers: true,
      },
    });
    const formatNumber = (val) => {
      let formatedValue = val;
      if (useIndianFormat) {
        formatedValue = val.toLocaleString('en-IN');
      } else if (useComma) {
        formatedValue = val.toLocaleString('en-US');
      }
      return `${prefix}${formatedValue}${suffix}`;
    };
    if (animate) {
      tl.to(count, duration, {
        value,
        ease,
        roundProps: 'value',
        onUpdate: () => {
          ref.current.innerHTML = formatNumber(count.value);
        },
      });
    }
    baseRef.current.innerHTML = formatNumber(value);
    return () => {
      if (tl) tl.kill();
    };
  }, []);
  return (
    <div
      className={`inline-flex ${
        alwaysCenter ? 'justify-center' : 'md:justify-center'
      }`}>
      <span ref={ref} className="text-inherit absolute">
        {value}
      </span>
      <span className="invisible" ref={baseRef} />
    </div>
  );
};
CountUp.defaultProps = {
  useIndianFormat: false,
  useComma: false,
  value: 0,
  startValue: 0,
  duration: 2,
  suffix: '',
  prefix: '',
  ease: 'power1.inOut',
  animate: true,
  alwaysCenter: true,
};
CountUp.propTypes = {
  useIndianFormat: PropTypes.bool,
  useComma: PropTypes.bool,
  value: PropTypes.number,
  startValue: PropTypes.number,
  duration: PropTypes.number,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  ease: PropTypes.string,
  animate: PropTypes.bool,
  alwaysCenter: PropTypes.bool,
};
export default CountUp;
