import React from 'react';

import TabPanel from '../components/Tabs/TabPanel';

export const checkList = [
  { id: 0, listItem: 'Secure' },
  { id: 1, listItem: 'Scalable' },
  { id: 2, listItem: 'Compliant' },
];

export const ourSolution = [
  {
    id: 0,
    title: 'Term Loans for individuals & businesses',
    image: 'term-loan.svg',
    disabled: false,
  },
  {
    id: 2,
    title: (
      <>
        Buy Now <span className="block">Pay Later</span>
      </>
    ),
    image: 'buynow-paylater.svg',
    disabled: false,
  },
  {
    id: 1,
    title: 'Co-lending',
    image: 'lending.svg',
    disabled: false,
  },

  {
    id: 3,
    title: (
      <>
        Invoice<span className="block">Discounting</span>
      </>
    ),
    image: 'invoice-discounting.svg',
    disabled: false,
  },
  {
    id: 4,
    title: 'Supply Chain Finance ',
    image: 'supply-chain.svg',
    disabled: false,
  },
  {
    id: 5,
    title: 'Credit Line Infrastructure',
    image: 'credit-line.svg',
    disabled: true,
  },
];

export const bankSliderData = [
  {
    id: 0,
    src: 'icici.svg',
    alt: 'icici',
  },
  {
    id: 1,
    src: 'kotak.svg',
    alt: 'kotak',
  },

  {
    id: 2,
    src: 'yes-bank.svg',
    alt: 'Yes Bank',
  },
  {
    id: 3,
    src: 'induslnd.svg',
    alt: 'Indusind Bank',
  },
  {
    id: 4,
    src: 'axis.svg',
    alt: 'Axis Bank',
  },
  {
    id: 5,
    src: 'hdfc.svg',
    alt: 'Hdfc Bank',
  },
];

export const sliderSettings = {
  dots: false,
  arrows: false,
  swipe: false,
  pauseOnHover: false,
  draggable: false,
  centerMode: false,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 2000,
  rtl: false,
  cssEase: 'linear',
  infinite: false,
  slidesToShow: 6,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        variableWidth: true,
      },
    },
  ],
};
export const merchantDashboard = [
  {
    id: 0,
    text: (
      <>
        Add borrowers and make up to 10,000 payouts in seconds from
        NBFC&nbsp;escrow
      </>
    ),
  },
  {
    id: 1,
    text: <>Track transfer status and reasons for failure in real&nbsp;time</>,
  },
  {
    id: 2,
    text: <>Generate custom reports for deeper&nbsp;analysis</>,
  },
];
export const instantLoanDisbursal = [
  {
    id: 0,
    icon: 'multiple-accounts.svg',
    title: (
      <h2 className="inline font-body">
        Disburse loans from multiple accounts
      </h2>
    ),
    text: (
      <>
        Connect multiple existing current or escrow accounts and switch between
        them for&nbsp;payout.
      </>
    ),
  },
  {
    id: 1,
    icon: 'colending.svg',
    title: 'Enable co-lending use cases with NBFC escrow',
    text: (
      <>
        Create flexible virtual accounts and use APIs on your NBFC escrow
        account to enable co-lending use&nbsp;case.
      </>
    ),
  },
  {
    id: 2,
    icon: 'reduce-dependency.svg',
    title: 'Reduce dependency on a single bank',
    text: (
      <>
        Switch between connected bank accounts. Avoid any unforeseen exigencies
        of bank downtimes with multi-bank&nbsp;support.
      </>
    ),
  },
  {
    id: 3,
    icon: 'lightining-speed.svg',
    title: 'Get support at lightning speed',
    text: (
      <>
        Get dedicated support to set up multiple party escrows or trustee
        escrows for&nbsp;disbursal.
      </>
    ),
  },
];

export const faqData = [
  {
    id: 0,
    q: 'Is Cashfree Payments compliant with the new RBI Lending Guidelines?',
    a: <p>Yes</p>,
  },
  {
    id: 1,
    q: 'Why were the new Lending Guidelines needed?',
    a: (
      <>
        <p>
          With the digitisation of lending, collaborations between entities are
          common. For instance, regulated entities (REs) like banks or NBFC may
          partner with:
        </p>
        <ul className="list-decimal">
          <li>
            Non-bank companies that provide Digital Lending Applications (DLAs)
          </li>
          <li>
            Lending Service Providers (LSPs) that undertake sections of the
            lending process. These LSPs and DLAs do not provide capital nor
            carry credit risks. This may lead to unregistered lending and
            anonymised shadow lending.
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 2,
    q: 'What are the implications of digital lending guidelines?',
    a: (
      <>
        <p>
          <b>Can’t use pass-through accounts for fund flow:</b> Disbursals and
          collections will flow straight from the RE’s bank account to the
          borrower’s bank account.
        </p>
        <p>
          <b>BNPL models disbursing to PPI discontinued:</b> BNPL Models that
          disbursed funds through PPI (prepaid cards, m-wallets) are
          discontinued. Funds should be disbursed directly to the bank account
          of the borrower.
        </p>
        <p>
          <b>Direct disbursals to merchants allowed for specific cases:</b>{' '}
          These use cases include invoice discounting, supply chain financing,
          consumer durable financing, and even BNPL.
        </p>
        <p>
          <b>Exceptions for co-lending models:</b> Pool accounts can be still
          used for for the flow of funds between multiple co-lending REs.
        </p>
        <p>
          <b>First Loss Default Guarantee (FLDG) use under examination:</b>{' '}
          First Loss Default Guarantee (FLDG) models allow LSPs to offer a first
          loss guarantee up to a certain percentage of the loan, allowing it to
          bear some of the risk. Until further notice, arrangements need to
          adhere to securitisation norms.
        </p>
      </>
    ),
  },
  {
    id: 3,
    q: 'What is Cashfree Payments’ offering for NBFCs & LSPs?',
    a: (
      <>
        <p>
          Cashfree Payments’ tailored solution enables NBFCs to open and operate
          Escrow accounts with partner banks and trustees, with a dashboard and
          API stack for loan disbursements directly to borrowers, efficient
          reconciliation, borrower identity & bank account verification.
        </p>
        <p>
          This solution will facilitate both Digital lending and Co-lending use
          cases and will enable both lenders and LSPs to comply with the recent
          guidelines on digital lending with minimal changes to their existing
          workflow. Cashfree Payments also enables lenders to provide seamless
          repayment options such as e-NACH, UPI AutoPay and identity
          verification products like Bank Account, GSTIN, PAN, Aadhar
          verification, thereby giving a full-stack solution for NBFCs and their
          LSPs.
        </p>
      </>
    ),
  },
  {
    id: 4,
    q: 'What is an Escrow Account in India?',
    a: (
      <p>
        An escrow account in India is a bank account with conditions on
        ownership of funds. In simple terms, it is a safe house for assets while
        the transaction process is still ongoing. An escrow agent is a mediator
        who holds this escrow account. Consider them an intermediary between the
        buyer and the seller. Interestingly, an escrow account is not just
        limited to funds. It can be used for different types of assets like
        money, stocks, funds etc.
      </p>
    ),
  },
  {
    id: 5,
    q: 'Why do you need an Escrow Account in India?',
    a: (
      <>
        <p>You may need an escrow account in case of:</p>
        <ul className="list-decimal">
          <li>A large transaction (between two or more parties)</li>
          <li>that have some legal obligations attached to them</li>
          <li>
            which need to be fulfilled before the release of the payment or an
            asset.
            <div>
              Here’s an example: Let’s say you are a construction builder and
              aim to sell apartments. Now, let’s assume that you aim to get
              these apartments booked by customers before they are ready to move
              in. Naturally, there are transaction risks.{' '}
            </div>
          </li>
          <li>
            The customer may not want to pay the full amount before possession
          </li>
          <li>
            There is a risk of scams (money bring redirected to other avenues)
          </li>
          <li>
            Customers may be wary if predetermined conditions are not fulfilled
          </li>
        </ul>

        <p>
          Here’s where an escrow account in India steps in. It reduces the risk
          of fraud as it acts as the third party between the two parties.
          Moreover, it helps control the cash flow between the two parties.
        </p>
        <p>
          Additionally, since September 2022, escrow accounts have become
          indispensable for co-lending use cases. According to the{' '}
          <a
            href="https://www.cashfree.com/blog/digital-lending-guidelines/"
            className="external-link"
            target="_blank"
            rel="noreferrer">
            digital lending guidelines
          </a>{' '}
          by RBI, all loan repayment and disbursement must be done directly
          between the bank account of lender and borrower. This means that no
          third party pass-through account can be used by banks, NBFCs or
          lending service providers (LSPs).
        </p>
        <p>
          However, co-lending use cases are an exception to this rule. In other
          terms,{' '}
          <b>
            co-lending players (like banks and NBFCs) can use escrow accounts
          </b>{' '}
          to pool funds before disbursing to borrowers.
        </p>
      </>
    ),
  },
  {
    id: 6,
    q: 'How to open an Escrow Account in India?',
    a: (
      <>
        <ul className="list-disc">
          <li>
            The buyer and the seller agree before opening the account, which
            involves the terms and conditions of the buying and selling assets.
            This agreement is the “Escrow agreement”.
          </li>
          <li>
            After reviewing and signing the agreement, the buyer deposits the
            amount to the escrow account.
          </li>
          <li>
            The escrow agent verifies and validates the receipt to both parties.
          </li>
          <li>
            The escrow agent regularly monitors the buyer’s account to confirm
            whether or not the buyer is paying the seller as per the agreed
            process.
          </li>
          <li>
            Subject to the positive response from both parties, the assets under
            the escrow agent’s control are released at the agreed event or time.
          </li>
          <li>
            If there is any disagreement between the two parties, the escrow
            account in India will move to dispute resolution. The outcome
            process of the dispute resolution decides what to do with the escrow
            funds.
          </li>
        </ul>
        <p>
          However, if you are a co-lending player (NBFC or fintech), you can
          directly integrate with full stack digital lending service providers,
          like Cashfree Payments.
        </p>
        <p>
          This will allow you to <b>integrate and go-live within 3 weeks.</b>
        </p>
        <p>
          Moreover, Cashfree Payments’ low code APIs & dashboard access can help
          you in easy loan disbursement & collections as well, in compliance
          with RBI’s digital lending guidelines.
        </p>
      </>
    ),
  },
];

const headingClass = 'mb-2 md:mb-[12px]';
const contentBodyMarginClass = 'mb-1 md:mb-8 max-w-[500px]';
const imageClassName = 'w-full md:pr-[18px]';
const alignStart = true;
const contentClassName = 'md:pl-[19px] mt-0 lg:pt-[60px] md:pt-[30px]';
const mobileVisibleHeading = false;
export const digitalLendingGuidelines = [
  {
    key: 0,
    heading: 'Collections and reconciliation',
    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/nbfc/guidelines-1.svg',
            width: '610',
            height: '440',
          }}
          heading="Collections and reconciliation"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Receive loan repayment directly into your NBFC account. Instant
                reconciliation & same day&nbsp;settlement.
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_CollectionsAndReconciliation">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 1,
    heading: 'Recurring payments',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/nbfc/guidelines-2.svg',
            width: '610',
            height: '440',
          }}
          heading="Recurring payments "
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Set up recurring mandates for repayment. Collect recurring
                payments via debit cards and Net Banking using NACH E-mandate &
                UPI Autopay using TPV&nbsp;flow
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_RecurringPayments">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
  {
    key: 2,
    heading: <div>Split payments</div>,

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/nbfc/guidelines-3.svg',
            width: '610',
            height: '440',
          }}
          heading={<div>Split payments</div>}
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Split repayment received and settle into co-lenders’
                accounts&nbsp;directly
              </div>
            </>
          }
          footer={
            <>
              <div className="bg-cf-primary px-4 pt-[33px] pb-3 text-base text-white rounded relative mt-4 border border-cf-stroke max-w-[286px] ml-[4px] md:ml-0">
                <div className="ribbon-purple">Recommended for</div>
                <span className="block mt-1 text-2.5sm leading-[24px]">
                  co-lending use cases
                </span>
              </div>
              <a
                className="button button-green mt-8  mr-4 wide w-full md:w-auto"
                href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_SplitPayments">
                Contact Sales <span className="chevron" />
              </a>
            </>
          }
        />
      </div>
    ),
  },
  {
    key: 3,
    heading: 'Real-time reports',

    content: (
      <div className="md:mt-[60px] pb-[12px] md:pb-0">
        <TabPanel
          headingClass={headingClass}
          img={{
            src: '/img/nbfc/guidelines-4.svg',
            width: '610',
            height: '440',
          }}
          heading="Real-time reports"
          imageClassName={imageClassName}
          alignStart={alignStart}
          contentClassName={contentClassName}
          mobileVisibleHeading={mobileVisibleHeading}
          body={
            <>
              <div className={contentBodyMarginClass}>
                Leverage repayment reports and statements data for
                accounting&nbsp;compliance
              </div>
            </>
          }
          footer={
            <a
              className="button button-green mt-8 md:mt-0 mr-4 wide w-full md:w-auto"
              href="https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_RealtimeReports">
              Contact Sales <span className="chevron" />
            </a>
          }
        />
      </div>
    ),
  },
];

export const verificationData = [
  {
    id: 0,
    image: {
      src: 'verification-1.svg',
      msrc: 'verification-1-mb.svg',
    },
    title: 'Bank Account Verification',
  },
  {
    id: 1,
    image: {
      src: 'verification-2.svg',
      msrc: 'verification-2-mb.svg',
    },
    title: 'UPI ID Verification',
  },
  {
    id: 2,
    image: {
      src: 'verification-3.svg',
      msrc: 'verification-3-mb.svg',
    },
    title: 'PAN Verification',
  },
  {
    id: 3,
    image: {
      src: 'verification-4.svg',
      msrc: 'verification-4-mb.svg',
    },
    title: 'Aadhaar Verification',
  },
  {
    id: 4,
    image: {
      src: 'verification-5.svg',
      msrc: 'verification-5-mb.svg',
    },
    title: 'GSTIN Verification',
  },
];

export const riskMitigation = [
  {
    id: 0,
    text: 'Set transaction limits',
  },
  {
    id: 1,
    text: 'API level security checks',
  },
  {
    id: 2,
    text: 'Fraud detection',
  },
];

export const perksCards = [
  {
    id: 0,
    icon: '/img/nbfc/nbfc-icon.svg',
    title: 'NBFCs',
    perks: [
      {
        id: 0,
        text: (
          <>
            Get{' '}
            <span className="text-white font-medium">
              fully managed escrow set-up
            </span>{' '}
            with&nbsp;
            <span className="text-white font-medium">
              multiple banking partners
            </span>
          </>
        ),
      },
      {
        id: 1,
        text: (
          <>
            Get access to detailed and customized MIS{' '}
            <span className="text-white font-medium">report</span>
          </>
        ),
      },
      {
        id: 2,
        text: (
          <>
            Enable LSPs to manage{' '}
            <span className="text-white font-medium">
              complete disbursement
            </span>{' '}
            &&nbsp;
            <span className="text-white font-medium">repayment cycle</span> on
            Cashfree Payment&apos;s Dashboard or use our APIs
          </>
        ),
      },
      {
        id: 3,
        text: (
          <>
            Get{' '}
            <span className="text-white font-medium">
              access to loans, disbursed statements
            </span>{' '}
            and balance for each LSP on your escrow
          </>
        ),
      },
    ],
    link:
      'https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_EscrowSolutionNBFC',
  },
  {
    id: 1,
    icon: '/img/nbfc/lsp-icon.svg',
    title: 'LSPs',
    perks: [
      {
        id: 0,
        text: (
          <>
            Manage <span className="text-white font-medium">multiple NBFC</span>{' '}
            relationships through our solution
          </>
        ),
      },
      {
        id: 1,
        text: (
          <>
            Ensure <span className="text-white font-medium">visibility</span>{' '}
            and ability to manage the{' '}
            <span className="text-white font-medium">loan cycle</span> for your
            customer base
          </>
        ),
      },
      {
        id: 2,
        text: (
          <>
            Get access to{' '}
            <span className="text-white font-medium">dashboard</span> and APIs
            for{' '}
            <span className="text-white font-medium">
              disbursement and repayment
            </span>{' '}
            on <span className="text-white font-medium">NBFC escrow</span>
          </>
        ),
      },
      {
        id: 3,
        text: (
          <>
            Get access to detailed and customized MIS{' '}
            <span className="text-white font-medium">report for loans</span>{' '}
            originated and repaid for your customers
          </>
        ),
      },
    ],
    link:
      'https://www.cashfree.com/contact-sales?source-action=NBFC%20lending%20solution&action=Contact%20Sales&button-id=ContactSales_EscrowSolutionLSP',
  },
];

export const paymentPartnerStats = [
  {
    id: 0,
    countUpProps: {
      value: 300000,
      suffix: '+',
      useIndianFormat: true,
    },
    desc: 'Merchants',
  },
  {
    id: 1,
    countUpProps: {
      value: 40,
      prefix: 'USD',
      suffix: 'B+',
    },

    desc: 'Processed annually',
  },
  {
    id: 2,
    countUpProps: {
      value: 200,
      suffix: 'M+',
    },
    desc: 'Bank accounts served',
  },
];

export const northernArcCards = [
  {
    id: '01',
    boldText: 'Verify borrower bank accounts',
    image: '/img/nbfc/verify-borrower.svg',
    desc: <>before disbursing consumer & business&nbsp;loans.</>,
  },
  {
    id: '02',
    boldText: 'Save man hours',
    image: '/img/nbfc/savehours.svg',
    desc: (
      <>
        by disbursing thousands of consumer & business loans&nbsp;automatically.
      </>
    ),
  },
  {
    id: '03',
    boldText: 'Automatically reconcile',
    image: '/img/nbfc/automatic-reconcile.svg',
    desc: <>failed transfers and&nbsp;reversals.</>,
  },
];

export const comparisonTable = [
  {
    id: 0,
    title: 'Time to go live',
    columns: [
      {
        id: 0,
        content: (
          <>
            <span className="font-semibold">Easy to integrate</span> and go-live
            in 3 weeks
          </>
        ),
      },
      {
        id: 1,
        content: (
          <>
            <span className="font-semibold">Difficult to integrate</span>.
            Delayed onboarding
          </>
        ),
      },
    ],
  },
  {
    id: 1,
    title: 'Product Suite for different payment needs',
    columns: [
      {
        id: 0,
        content: (
          <>
            <span className="font-semibold">One-stop solution</span>. Low code
            APIs & dashboard access for easy loan disbursement & collections
          </>
        ),
      },
      {
        id: 1,
        content: (
          <>
            <span className="font-semibold">
              Fragmented and siloed products
            </span>
            . Multiple vendor integrations required
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: 'Ease of service',
    columns: [
      {
        id: 0,
        content: (
          <>
            <span className="font-semibold">Dedicated support</span> in escrow
            creation
          </>
        ),
      },
      {
        id: 1,
        content: (
          <>
            <span className="font-semibold">Multiple points of contact</span>
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    title: 'Dashboard',
    columns: [
      {
        id: 0,
        content: (
          <>
            <span className="font-semibold">
              Connect multiple escrow/current accounts{' '}
            </span>
            with single&nbsp;dashboard
          </>
        ),
      },
      {
        id: 1,
        content: (
          <>
            Multiple dashboards, and{' '}
            <span className="font-semibold">limited</span> view
          </>
        ),
      },
    ],
  },
];
