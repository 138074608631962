import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';

const POINT_MAP = {
  'purple-tick': (
    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px] bg-cf-primary flex-none">
      <Icon name="tick" width="9.21px" height="6.29px" fill="#fff" />
    </span>
  ),
  'green-tick': (
    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px] bg-cf-green-400 flex-none">
      <Icon name="tick" width="9.21px" height="6.29px" fill="#fff" />
    </span>
  ),
  'dark-cancel': (
    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px]  bg-black flex-none">
      <Icon name="lightclose" width="9.21px" height="6.29px" fill="#fff" />
    </span>
  ),
  'red-cancel': (
    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px] bg-cf-red-400 flex-none">
      <Icon name="lightclose" width="9.21px" height="6.29px" fill="#fff" />
    </span>
  ),
  'red-purple-cancel': (
    <span className="w-5 h-5 flex justify-center items-center rounded-full mt-[2px] mr-[8px] bg-cf-red-purple flex-none">
      <Icon name="lightclose" width="9.21px" height="6.29px" fill="#fff" />
    </span>
  ),
};
function BulletPoint({ className, text, type }) {
  return (
    <div className="flex flex-row items-start w-full">
      {POINT_MAP[type]}
      <span className={`font-body ${className}`}>{text}</span>
    </div>
  );
}

BulletPoint.defaultProps = {
  className: 'text-2md',
  text: '',
  type: '',
};
BulletPoint.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf([
    '',
    'purple-tick',
    'green-tick',
    'dark-cancel',
    'red-cancel',
    'red-purple-cancel',
  ]),
};

export default BulletPoint;
